$darkdarkGrayBackground: #202225;
$lighterGray: #2e3136;
$evenLighterGray: #36393e;
$fullWhite: #ffffff;

.App {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.align-center {
    align-items: center;
}

.avatar-button {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
    -ms-overflow-style: none;
}

.dropdown:focus {
    outline: none;
    box-shadow: none;
}

.show-click {
    cursor: pointer;
}

.textareaElement {
    max-width: 700px;
    width: auto;
    min-height: 17px;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}

.timezone-select > select {
    color: white;
    background-color: #2e3136;
}

.timezone-select > option {
    color: white;
    background-color: #2e3136;
}

.timezone-select {
    color: white;
    background-color: #2e3136;
}

.timezone-select > input {
    color: white;
    background-color: #2e3136;
}

.timezone-select > span {
    color: white;
    background-color: #2e3136;
}

.timezone-select > div {
    color: white;
    background-color: #2e3136;
}

.export-btn div path {
    stroke: #ef5350;
}

.export-btn-white path {
    stroke: #ffffff;
}
